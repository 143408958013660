import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import EmailRecipients from '../EmailRecipients';

const ReadEmail = ({ emailSubject, emailRecipients, message, onBackButtonClick }) => (
  <>
    <Header title={emailSubject} showBackButton onBackButtonClick={onBackButtonClick} />
    <EmailRecipients recipients={emailRecipients} />
    <div>{message}</div>
  </>
);

ReadEmail.propTypes = {
  emailSubject: PropTypes.string.isRequired,
  emailRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  message: PropTypes.string.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
};

export default ReadEmail;
