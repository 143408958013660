import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './AppLayout.module.scss';
import Menu from '../Menu';

const AppLayout = ({ menuItems, children }) => (
  <div className={styles.appLayout}>
    <header className={styles.header}>
      <h1>Email App</h1>
    </header>
    <div className={styles.content}>
      <Menu menuItems={menuItems} className={styles.menu} />
      <main className={styles.mainContent}>{children}</main>
    </div>
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </div>
);

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      isExact: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default AppLayout;
