import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import emails from '../../data/emails';
import ReadEmail from '../../components/ReadEmail';

const ViewEmail = () => {
  const params = useParams();
  const history = useHistory();

  const email = emails.find(({ id }) => id === params.emailId);

  if (!email) return <p>Email not found.</p>;

  const { subject, message, bcc } = email;

  return (
    <ReadEmail
      emailSubject={subject}
      emailRecipients={bcc}
      message={message}
      onBackButtonClick={() => history.goBack()}
    />
  );
};

export default ViewEmail;
