import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import WriteEmail from '../../components/WriteEmail';
import ScheduleEmail from '../../components/ScheduleEmail';
import emails from '../../data/emails';

const EmailEditor = () => {
  const params = useParams();
  const history = useHistory();
  const email = emails.find((e) => e.id === params.emailId);

  const [showDatepicker, setShowDatepicker] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [recipients, setRecipients] = useState(email?.bcc || []);
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState(email?.subject || '');
  const [message, setMessage] = useState(email?.message || '');

  const addRecipient = () => {
    const isValidEmail = () => {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(recipient);
    };

    if (isValidEmail()) {
      setRecipients((currentRecipients) =>
        !currentRecipients.includes(recipient)
          ? [...currentRecipients, recipient]
          : currentRecipients
      );

      setRecipient('');
    } else {
      toast.error('Invalid email address!');
    }
  };

  const goBack = () => {
    if (email) {
      history.goBack();
    } else {
      history.push('/new-email');
    }
  };

  const resetInputFields = () => {
    setRecipients([]);
    setSubject('');
    setMessage('');
  };

  const handleRecipientChange = (e) => {
    setRecipient(e.target.value);
  };

  const handleRecipientBlur = () => {
    if (recipient) addRecipient();
  };

  // Adds recipient to the recipients array when the user presses enter if the BCC field is focused
  const handleEnterPress = (e) => {
    if (e.charCode === 13 && recipient) addRecipient();
  };

  const handleRecipientRemove = (recipientToRemove) => {
    setRecipients((allRecipients) => allRecipients.filter((r) => r !== recipientToRemove));
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendEmail = () => {
    if (recipients.length && subject && message) {
      toast.info('Your email was sent!');
      resetInputFields();
      goBack();
    } else {
      toast.error('Please fill out all fields!');
    }
  };

  const handleScheduleEmail = () => {
    if (recipients.length && subject && message) {
      setShowDatepicker(true);
    } else {
      toast.error('Please fill out all fields!');
    }
  };

  const handleScheduleNow = () => {
    setShowDatepicker(false);
    goBack();
    resetInputFields();
    toast.info(`Your email was scheduled for ${scheduleDate}`);
  };

  const handleDateChange = (newDate) => {
    setScheduleDate(newDate);
  };

  if (showDatepicker) {
    return (
      <ScheduleEmail
        scheduleDate={scheduleDate}
        onBackButtonClick={() => {
          setShowDatepicker(false);
        }}
        onSchedule={handleScheduleNow}
        onDateChange={handleDateChange}
      />
    );
  }

  return (
    <WriteEmail
      headerTitle={email ? 'Edit Email' : 'New Email'}
      showBackButton={!!email}
      onBackButtonClick={goBack}
      recipients={recipients}
      recipient={recipient}
      subject={subject}
      message={message}
      onRecipientChange={handleRecipientChange}
      onRecipientBlur={handleRecipientBlur}
      onRecipientRemove={handleRecipientRemove}
      onEnterPress={handleEnterPress}
      onSubjectChange={handleSubjectChange}
      onMessageChange={handleMessageChange}
      onSend={handleSendEmail}
      onSchedule={handleScheduleEmail}
    />
  );
};

export default EmailEditor;
