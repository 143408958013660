import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from './IconButton.module.scss';

const IconButton = ({ color, size, type, className, icon, onClick }) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={classNames(styles.iconButton, styles[color], styles[size], className)}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} size={size === 'small' ? 'lg' : '2x'} />
  </button>
);

IconButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

IconButton.defaultProps = {
  color: 'primary',
  size: 'small',
  type: 'button',
  className: null,
};

export default IconButton;
