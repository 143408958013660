/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './WriteEmail.module.scss';

import Header from '../Header';
import Label from '../Label';
import TextInput from '../TextInput';
import EmailRecipients from '../EmailRecipients';
import Button from '../Button';

const WriteEmail = ({
  headerTitle,
  showBackButton,
  onBackButtonClick,
  recipients,
  recipient,
  onRecipientChange,
  onRecipientBlur,
  onRecipientRemove,
  onEnterPress,
  subject,
  onSubjectChange,
  message,
  onMessageChange,
  onSend,
  onSchedule,
}) => (
  <div>
    <Header
      title={headerTitle}
      showBackButton={showBackButton}
      onBackButtonClick={onBackButtonClick}
    />
    <div className={styles.inputGroup}>
      <div className={styles.recipientsContainer}>
        <Label className={`${styles.marginRight} ${styles.marginBottom}`} htmlFor="bcc">
          BCC
        </Label>
        {recipients.length > 0 && (
          <EmailRecipients recipients={recipients} onRemove={onRecipientRemove} />
        )}
      </div>
      <TextInput
        id="bcc"
        value={recipient}
        placeholder="Add recipient"
        onChange={onRecipientChange}
        onBlur={onRecipientBlur}
        onKeyPress={onEnterPress}
      />
    </div>
    <div className={styles.inputGroup}>
      <Label htmlFor="subject" className={styles.marginBottom}>
        Subject
      </Label>
      <TextInput id="subject" value={subject} placeholder="Subject" onChange={onSubjectChange} />
    </div>
    <div className={styles.inputGroup}>
      <Label htmlFor="message" className={styles.marginBottom}>
        Message
      </Label>
      <textarea
        className={styles.textarea}
        rows="10"
        id="message"
        placeholder="Message"
        value={message}
        onChange={onMessageChange}
      />
    </div>

    <Button onClick={onSend} className={styles.marginRight}>
      Send
    </Button>
    <Button onClick={onSchedule}>Schedule</Button>
  </div>
);

WriteEmail.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  recipient: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onRecipientChange: PropTypes.func.isRequired,
  onSubjectChange: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onRecipientBlur: PropTypes.func.isRequired,
  onRecipientRemove: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onSchedule: PropTypes.func.isRequired,
};

export default WriteEmail;
