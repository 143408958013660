function Email(from, bcc, subject, message, status) {
  this.id = Math.ceil(Math.random() * 1000).toString();
  this.from = from;
  this.bcc = bcc;
  this.subject = subject;
  this.message = message;
  this.status = status;
}

const emails = [
  new Email(
    'davidcrodriguez@hotmail.com',
    ['marynanderson@gmail.com', 'michaelcisbell@yahoo.com'],
    'Test subject',
    'Hello\nThis is a test email',
    'sent'
  ),

  new Email(
    'alissajdoolittle@gmail.com',
    ['carolynrokeefe@hotmail.com', 'coreylhoff@gmail.com', 'arenawilkerson@hotmail.com'],
    'Just Another Email Subject',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.\nNulla ut sem congue, ornare elit ac, auctor tortor. Fusce fringilla convallis ipsum, vitae ultricies ante hendrerit sed.\nFusce efficitur enim ut nibh semper, quis sagittis metus mattis.',
    'scheduled'
  ),

  new Email(
    'kathleenmtanner@gmail.com',
    ['patricknray@yahoo.com'],
    'Lorem ipsum dolor sit amet',
    'Nulla ut sem congue, ornare elit ac, auctor tortor. Fusce fringilla convallis ipsum, vitae ultricies ante hendrerit sed.\nFusce efficitur enim ut nibh semper, quis sagittis metus mattis.',
    'sent'
  ),

  new Email(
    'leonardaabramson@hotmail.com',
    ['arvillaaeggleston@gmail.com', 'andrewtgarnica@gmail.com'],
    'Nulla ut sem congue, ornare elit ac',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'scheduled'
  ),

  new Email(
    'RubyJWatson@gmail.com',
    [
      'davidcrodriguez@hotmail.com',
      'andrewtgarnica@gmail.com',
      'carolynrokeefe@hotmail.com',
      'coreylhoff@gmail.com',
    ],
    'Fusce efficitur enim ut nibh semper, quis sagittis metus mattis',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'scheduled'
  ),
];

export default emails;
