import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import IconButton from '../IconButton';

const Header = ({ title, showBackButton, onBackButtonClick }) => (
  <header className={styles.header}>
    {showBackButton && (
      <IconButton onClick={onBackButtonClick} icon="arrow-left" className={styles.backButton} />
    )}
    <h2>{title}</h2>
  </header>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func,
};

Header.defaultProps = {
  onBackButtonClick: () => {},
};

export default Header;
