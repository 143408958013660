import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../Header';
import Button from '../Button';

const ScheduleEmail = ({ scheduleDate, onBackButtonClick, onDateChange, onSchedule }) => (
  <>
    <Header title="Schedule Email" showBackButton onBackButtonClick={onBackButtonClick} />
    <DatePicker
      showTimeSelect
      inline
      selected={scheduleDate}
      onChange={(date) => onDateChange(date)}
    />
    <Button onClick={onSchedule}>Schedule Now</Button>
  </>
);

ScheduleEmail.propTypes = {
  scheduleDate: PropTypes.instanceOf(Date).isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onSchedule: PropTypes.func.isRequired,
};

export default ScheduleEmail;
