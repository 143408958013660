import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmailPreview.module.scss';

const EmailPreview = ({ recipients, subject, message, onClick }) => (
  <div onClick={onClick} className={styles.emailPreview} role="presentation">
    <div className={styles.emailRecipients}>{recipients.join(', ')}</div>
    <div className={styles.subject}>{subject}</div>
    <div className={styles.message}>{message}</div>
  </div>
);

EmailPreview.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  subject: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EmailPreview;
