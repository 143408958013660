import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmailRecipients.module.scss';
import IconButton from '../IconButton';

const EmailRecipients = ({ recipients, onRemove }) => (
  <ul className={styles.emailRecipients}>
    {recipients.map((recipient) => (
      <li className={styles.recipient} key={recipient}>
        {recipient}
        {onRemove && (
          <IconButton
            icon="times"
            color="secondary"
            onClick={() => onRemove(recipient)}
            className={styles.removeButton}
          />
        )}
      </li>
    ))}
  </ul>
);

EmailRecipients.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemove: PropTypes.func, // optional because recipients can't be removed from already sent emails!
};

EmailRecipients.defaultProps = {
  onRemove: null,
};

export default EmailRecipients;
