import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import '../../styles/index.scss';
import '../../utils/iconLibrary';
import AppLayout from '../AppLayout';
import EmailEditor from '../../pages/EmailEditor';
import EmailList from '../../pages/EmailList';
import ViewEmail from '../../pages/ViewEmail';

import menuItems from '../../data/menuItems';

const App = () => (
  <BrowserRouter>
    <AppLayout menuItems={menuItems}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/new-email" />
        </Route>
        <Route path="/new-email">
          <EmailEditor />
        </Route>
        <Route path="/edit/:emailId">
          <EmailEditor />
        </Route>
        <Route path="/sent">
          <EmailList status="sent" />
        </Route>
        <Route path="/scheduled">
          <EmailList status="scheduled" />
        </Route>
        <Route path="/read/:emailId">
          <ViewEmail />
        </Route>
        <Route path="/*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </AppLayout>
  </BrowserRouter>
);

export default App;
