import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Label.module.scss';

const Label = ({ htmlFor, children, className }) => (
  <label className={classNames(styles.label, className)} htmlFor={htmlFor}>
    {children}
  </label>
);

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Label.defaultProps = {
  className: null,
};

export default Label;
