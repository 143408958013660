import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import emails from '../../data/emails';
import Header from '../../components/Header';
import EmailPreview from '../../components/EmailPreview';

const EmailList = ({ status }) => {
  const emailsToShow = emails.filter((email) => email.status === status);
  const history = useHistory();

  const handleClick = (emailId) => {
    if (status === 'sent') {
      history.push(`/read/${emailId}`);
    }

    if (status === 'scheduled') {
      history.push(`/edit/${emailId}`);
    }
  };

  return (
    <>
      <Header
        title={status === 'sent' ? 'Sent Emails' : 'Scheduled Emails'}
        showBackButton={false}
      />
      {emailsToShow.map(({ id, bcc, subject, message }) => (
        <EmailPreview
          key={id}
          recipients={bcc}
          subject={subject}
          message={message}
          onClick={() => handleClick(id)}
        />
      ))}
    </>
  );
};

EmailList.propTypes = {
  status: PropTypes.oneOf(['sent', 'scheduled']).isRequired,
};

export default EmailList;
