const menuItems = [
  {
    label: 'New Email',
    path: '/new-email',
    isExact: false,
  },
  {
    label: 'Sent',
    path: '/sent',
    isExact: false,
  },
  {
    label: 'Scheduled',
    path: '/scheduled',
    isExact: false,
  },
];

export default menuItems;
