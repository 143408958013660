import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './Menu.module.scss';

const Menu = ({ menuItems, className }) => (
  <nav className={className}>
    {menuItems.map(({ label, path, isExact }) => (
      <NavLink
        key={path}
        to={path}
        exact={isExact}
        className={styles.menuItem}
        activeClassName={styles.active}
      >
        {label}
      </NavLink>
    ))}
  </nav>
);

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      isExact: PropTypes.bool.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
