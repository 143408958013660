/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TextInput.module.scss';

const TextInput = ({ id, value, placeholder, onChange, onBlur, onFocus, className, ...props }) => (
  <input
    className={classNames(styles.textInput, className)}
    id={id}
    type="text"
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    onFocus={onFocus}
    placeholder={placeholder}
    {...props}
  />
);

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
};

TextInput.defaultProps = {
  placeholder: '',
  onBlur: () => {},
  onFocus: () => {},
  className: '',
};

export default TextInput;
